@use 'styles/theme/theme' as theme;

.modalContainer {
  min-width: 1280px;
  max-width: 1280px;
}

.modalRoot {
  padding: 20px 0;
}

.menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid theme.$tertiaryGrey;
  }
  &__backSection {
    display: flex;
    align-items: center;
    column-gap: 4px;
    cursor: pointer;
    svg {
      fill: theme.$secondaryGrey !important;
    }
  }
  &__logoSection {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  &__logoImage {
    width: 140px;
    height: 14px;
  }
  &__headerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px;
  }
  &__headerWorks {
    &_list {
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        letter-spacing: 2px;
        &:first-child:before {
          content: '';
        }
        &:before {
          content: '\00a0\2022\00a0\00a0';
          color: theme.$orange3;
          letter-spacing: 1px;
        }
      }
    }
  }
  &__content {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 28px;
  }
  &__footer {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
    padding: 8px 24px;
    border-top: 1px solid theme.$tertiaryGrey;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: theme.$orangeLight;
    &_logos {
      width: 100%;
    }
  }
}

.icon {
  fill: theme.$primaryGrey !important;
  cursor: pointer;
}

.menuItem {
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  width: 234px;
}

.drawer {
  height: 90%;
  max-height: none !important;
  &__header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 20px 16px;
    border: 1px solid theme.$tertiaryGrey;
    background-color: theme.$backgroundGrey;
    svg {
      fill: theme.$primaryGrey !important;
      cursor: pointer;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 30% 70%;
  }
  &__leftSection {
    display: flex;
    flex-direction: column;
    background-color: theme.$whiteSmoke;
    &_item {
      display: flex;
      flex-direction: column;
      padding: 12px;
      row-gap: 4px;
      border-left: 3px solid transparent;
      cursor: pointer;
      border-right: 1px solid theme.$borderGrey;
      &:hover {
        background-color: theme.$white;
        border-left: 3px solid theme.$orange;
        border-right: none;
      }
    }
    &_selected {
      background-color: theme.$white;
      border-left: 3px solid theme.$orange;
      border-right: none;
    }
  }
  &__rightSection {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    overflow: auto;
    min-height: 84vh;
  }
}

.drawerItem {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
