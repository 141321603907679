@use 'styles/theme/theme' as theme;

.navLink {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: max-content;
  cursor: pointer;
  .icon {
    margin-left: 2px;
    fill: theme.$tertiaryGrey;
  }
  .item {
    line-height: 1.5;
    border-bottom: 2px solid transparent;
    &__selected {
      color: theme.$blue;
      font-weight: 500;
      border-bottom: 2px solid theme.$blue;
    }
  }
  &__active {
    .item {
      color: theme.$blue;
    }
    .icon {
      fill: theme.$blue !important;
    }
    .count {
      color: theme.$primaryGrey;
    }
  }
  @media screen and (min-width: 1280px) {
    &:hover {
      .item {
        color: theme.$blue;
      }
      .icon {
        fill: theme.$blue !important;
      }
    }
  }
}

.popover {
  pointer-events: none;
}

.underline {
  height: 2px;
  background-color: theme.$blue;
}

.popoverPaper {
  pointer-events: auto;
  width: 343px;
  height: 343px;
  padding: 21px 12px 16px 24px;
  margin-left: -24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid theme.$tertiaryGrey;
  background: #fff;
  box-shadow: 0px 0px 6px 0px theme.$blackT25, 0px 0px 4px 0px theme.$blackT25;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &__menu {
    gap: 16px;
  }
  &__drawer {
    gap: 20px;
  }
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  svg {
    visibility: hidden;
  }
  &:hover {
    span {
      color: theme.$blue;
    }
    svg {
      visibility: visible;
      fill: theme.$blue !important;
    }
    .option__label {
      text-decoration: underline !important;
    }
  }
  &__seasonLabel {
    white-space: break-spaces;
  }
  &__totalSection {
    display: inline-flex;
    align-items: center;
    .rightIcon {
      visibility: visible !important;
    }
  }
  &__rightIcon {
    visibility: visible !important;
    fill: theme.$tertiaryGrey !important;
  }
}

.entity {
  display: flex;
  flex-direction: column;

  &__menu {
    flex-direction: row;
    align-items: center;
  }
}
